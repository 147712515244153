.App-header {
  padding: 35px 0 50px;

  .App-logo {
    background-color: #fff;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .company-link,
  .nav-link {
    transition: color 0.25s ease;

    &:hover {
      color: var(--brand-color);
    }
  }
}