.App-Side {
  background-color: var(--brand-color);
  color: var(--theme-color-on-brand);
  border-radius: 40px 40px 0 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  padding: 1rem 2rem;

  @include media-breakpoint-up(xl) {
    & {
      border-radius: 20px;
      padding: 2rem;
    }
  }

  .icon-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 112px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    padding: 1rem;
    box-sizing: border-box;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      max-width: 57px;
    }
  }

  .progress-bar-wrapper {
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 15px 27px;

    .progress {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 8px;

      .progress-bar {
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
      }
    }
  }

  .selection-status-list-item {
    position: relative;

    @include media-breakpoint-up(xl) {
      & {
        padding-bottom: 1.2rem;
      }
    }

    @include media-breakpoint-down(xl) {
      & {
        width: 33.333%;

        &:not(:last-child):after {
          content: "";
          height: 1px;
          background-color: var(--theme-color-on-brand);
          left: calc(50% + 23px);
          position: absolute;
          top: 23px;
          width: calc(100% - 46px);
        }
      }
    }

    &:not(:last-child):after {
      content: "";
      height: 1px;
      background-color: var(--theme-color-on-brand);
      left: calc(50% + 23px);
      position: absolute;
      top: 23px;
      width: calc(100% - 46px);

      @include media-breakpoint-up(xl) {
        & {
          width: 1px;
          height: calc(100% - 46px);
          left: 23px;
          bottom: 0;
          top: auto;
        }
      }
    }


    .selection-status-list-item-inner {
      display: flex;

      @include media-breakpoint-down(xl) {
        & {
          justify-content: center;
        }
      }

      .selection-status-list-item-number {
        background-color: rgba(255, 255, 255, 0.2);
        width: 46px;
        height: 46px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .selection-status-list-item-number-inner {
          background-color: rgb(255, 255, 255);
          color: var(--brand-color);
          width: 23px;
          height: 23px;
          line-height: 23px;
          font-weight: bold;
          border-radius: 50%;
          display: block;
          text-align: center;

          svg {
            max-width: 15px;
          }
        }
      }

      .selection-status-list-item-content {
        padding-left: 0.7rem;
      }
    }

    &:not(.active) {
      .selection-status-list-item-number-inner {
        opacity: 0.5;
      }

      &:after {
        opacity: 0.5;
      }
    }

    &.selected {
      .selection-status-list-item-number:hover {
        cursor: pointer;
      }
    }
  }
}

.sticky {
  @include media-breakpoint-up(xl) {
    & {
      position: sticky;
      top: 5rem;
    }
  }

  @include media-breakpoint-down(xl) {
    & {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 400;

      .selection-status-list {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}