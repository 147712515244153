.date-wrapper {

  .card.success {
    pointer-events: none;
    opacity: 0.5;
  }

  .card-inner {
    min-height: 545px;
  }
}

.react-datepicker {
  background-color: #F2F3F5;
  border: none;
  border-radius: 10px;
  color: #292A31;
  display: inline-block;
  font-family: inherit;
  font-size: 18px;
  position: relative;
  width: 100%;
  //min-height: 450px;

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__navigation {
    top: 36px;
  }

  .react-datepicker__day--today,
  .react-datepicker__day {
    width: 35px;
    height: 35px;
    line-height: 30px;
    border-radius: 50%;
    font-weight: bold;
    margin: 7px 9px;
    background-color: transparent;
    transition: background-color 0.25s ease, border-color 0.25s ease;

    @media screen and (max-width: 456px) {
      & {
        margin: 3px 4px;
      }
    }

    &:not(.react-datepicker__day--disabled) {
      background-color: var(--brand-color);
      border: 3px solid var(--brand-color);
      color: var(--theme-color-on-brand);
      font-size: 16px;
    }
    
    &.react-datepicker__day--selected {
      background-color: var(--theme-color-on-brand);
      color: var(--brand-color);

    }
  }

  .react-datepicker__day--today {
      transition: none;
  }

  .react-datepicker__day-name {
    width: 35px;
    margin: 7px 9px;

    @media screen and (max-width: 456px) {
      & {
        margin: 3px 4px;
      }
    }
  }

  .react-datepicker__current-month {
    font-size: 18px;
    padding: 25px 0;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon:before,
  .react-datepicker__year-read-view--down-arrow {
    border-color: #292A31;
    border-style: solid;
    border-width: 2px 2px 0 0;
    height: 12px;
    top: 5px;
    width: 12px;
  }
}

.timeSelect {
  .form-check {
    padding-left: 0;
  }

  .btn {
    display: block;
  }
}

.failed-info {
  display: none;
}

.form-status-failed {
    .failed-info {
      display: block;
    }
}