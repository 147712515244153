:root {
  --brand-color: $primary;
  --secondary-color: #{$secondary};
  --theme-font-family: #{$font-family-base};
  --theme-color-on-brand: #{$colorOnBrand};

  --card-border-radius: #{$card-border-radius};
  --card-bg: #{$card-bg};
  --card-transparency: rgba(#{$card-bg}, 0.8);
  --card-border-color: #{$card-border-color-left};
}